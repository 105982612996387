import React, { Fragment } from "react"
import './banner.css'
class Banner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			height: '0px',
			arropacity: 0,
			arrma: '6rem'
		}
	}
	//轮播函数
	next = (type) => {
		const { index } = this.state
		const { children } = this.props;
		const next_index = type === 'next' ? index + 1 : index - 1
		if (next_index < 0) {
			this.setState({ index: children.length - 1 })
		} if (next_index > children.length - 1) {
			this.setState({ index: 0 })
		} else if (next_index >= 0 && next_index <= children.length - 1) {
			this.setState({ index: next_index })
		}
	}
	//按钮
	select = (select_index) => {
		this.setState({
			index: select_index
		})
	}
	timer = null
	autoplay = () => {
		this.timer = setInterval(() => {
			this.next("next")
		}, 15000)
	}
	stopautoplay = () => {
		clearInterval(this.timer)
	}
	componentDidMount () {
		const imgheight = document.getElementsByClassName('img')[0].offsetHeight || 500
		this.setState({
			height: imgheight + 'px'
		})
		this.autoplay()
	}
	componentWillUnmount () {
		this.stopautoplay()
	}
	boxmouseover = () => {
		this.stopautoplay();
		this.setState({
			arropacity: 0.6,
			arrma: '5rem'
		})
	}
	boxmouseout = () => {
		this.autoplay();
		this.setState({
			arropacity: 0,
			arrma: '6rem'
		})
	}
	render () {
		const { index, height, arrma, arropacity } = this.state;
		const { children } = this.props;
		const imgList = React.Children.map(children, (item, itemindex) => {
			return <div className={'img'} key={itemindex} style={{ 'opacity': index === itemindex ? 1 : 0, zIndex: index === itemindex ? 998 : itemindex }}>{item}</div>
		})
		const liList = React.Children.map(children, (item, itemindex2) => {
			return <li onMouseOver={this.boxmouseover} onClick={() => { this.select(itemindex2); this.stopautoplay() }} key={itemindex2} style={itemindex2 === index ? { opacity: 0.8 } : null}></li>
		})
		return (
			<Fragment>
				<div
					className={'imgoutbox'}
					style={{ height: height, with: '100%' }}
					onMouseOver={this.boxmouseover}
					onMouseOut={this.boxmouseout}
					onClick={this.stopautoplay}
				>
					{imgList}
				</div>
				{liList.length > 1 && <div className={'bottompoint'}>
					<ul className={'point'}>
						{liList}
					</ul>
				</div>}
				{liList.length > 1 && <div className={'arrow'} onMouseOver={this.boxmouseover}>
					<a
						style={{
							opacity: arropacity,
							marginLeft: arrma
						}}
						className={'prev'}
						onClick={
							() => {
								this.next('prev')
								this.stopautoplay()
							}
						}
					>
						<span className={'prev_first'}></span>
						<span className={'prev_last'}></span>
					</a>
					<a
						style={{
							opacity: arropacity,
							marginRight: arrma
						}}
						className={'next'}
						onClick={
							() => {
								this.next('next')
								this.stopautoplay()
							}
						}
					>
						<span className={'next_first'}></span>
						<span className={'next_last'}></span>
					</a>
				</div>}
			</Fragment>
		)

	}
}

export default Banner
